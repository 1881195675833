.bold {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.mt-nav {
    margin-top: 5rem;
}
.mapbox-improve-map {
    display: none;
}
.sticky-div {
    position: sticky;
    top: 1rem;
    z-index: 999;
}
.maxLine-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.w-md-75 {
    width: 75%;
}
.splide__pagination {
    display: none !important;
}
.splide__arrow--prev {
    left: .5rem !important;
}
.splide__arrow--next {
    right: .5rem !important;
}
#splide-bay:hover .splide__arrows {
    display: flex !important;
}
.splide__arrows {
    display: none;
}
.splide-title-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Firefox-specific properties */
    display: -moz-box;
    -moz-box-orient: vertical;
    -moz-box-lines: 2;
}
@media only screen and (max-width: 770px) {
    #label {
        display: none;
    }
    .w-md-75 {
        width: unset !important;
    }
}
@media only screen and (max-width: 400px) {
    .navbar-expand .navbar-nav .nav-link {
        margin-left: unset !important;
    }
}