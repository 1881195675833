.min-h-100 {
    min-height: 100% !important;
}
.pointer {
    cursor: pointer; 
}
.mr-1 {
    margin-right: .25rem;
}
.fit-content {
    width: fit-content
}