#fullWrapper {
    background: #fbfdff;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    top: 0
}
.bg-light-hover:hover {
    transition: all .2s ease-in-out;
    background: #f8f9fa;
}
#deadlinesDropdown {
    margin-top: calc(-1rem - 20px);
}
.mapboxgl-map {
    opacity: .9;
    transition: .125s opacity ease-in-out;
}
.mapboxgl-map:hover {
    opacity: 1
}
@media only screen and (max-width: 770px) {
    #picture {
        min-width: 45% !important;
        margin-top: -55% !important
    }
    #deadlinesDropdown {
        margin-left: -55% !important;
        width: 210% !important
    }
    .mobile-small {
        font-size: .875em;
    }
    .mobile-mt-3 {
        margin-top: 1rem !important;
    }
}
