.twemoji {
    width: 1.5rem
}
#basic-addon2 {
    background-color: #D3D3D3;
}
.spinner-border {
    width: 1.25rem;
    height: 1.25rem;
}
#loadingbtn {
    display: flex;
    align-items: center;
    justify-content: center;
}
*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.h4 {
    white-space: nowrap;
}
/*#homeMegaWrap {
    max-width: 1353px
}*/
.max-vw-100 {
    max-width: 100vw;
}
.text-left {
    text-align: left !important;
}
@media only screen and (max-width: 1000px) {
    #image {
        display: none !important;
    }
    #tooltip {
        display: none;
    }
    #wrapper {
        flex-basis: 100% !important;
    }
}
@media only screen and (max-width: 720px) {
    #megaWrapper {
        margin-left: unset !important;
        margin-right: unset !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }
    #megaWrapper > div > div, #megaWrapper > div {
        background-color: unset !important;
        box-shadow: unset !important;
    }
    #megaWrapper > div {
        padding: unset !important
    }
    #wrapper {
        width: 100%;
    }
    #personalFilter {
        display: unset !important;
    }
    .dropup {
        margin-bottom: .25rem;
    }
    #scnd {
        margin-left: unset !important;
        margin-bottom: .5rem;
    }
}
@media only screen and (max-width: 400px) {
    #wrapper {
        height: fit-content;
    }
    #megaWrapper > div {
        height: 100%;
    }
}
@media only screen and (max-width: 775px) {
    #uni-image {
        display: none;
    }
    #greenBg {
        display: none;
    }
    .expandOnMobile {
        width: 100vw !important;
    }
}
@media only screen and (max-width: 380px) {
    .map {
        display: none;
    }
}
@media only screen and (min-width: 1520px) {
    .expandable {
        height: calc(100% + (100vw / 10)) !important;
    }
    /*csslint ignore:start */
    #image {
        /* eslint-disable-next-line no-undef */
        height: auto;
    }
    /*/* csslint ignore:end */
    #tooltip {
        bottom: calc(-25px - (100vw / 50)) !important;
    }
}